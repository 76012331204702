import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import ServicesImages from "./ServicesImages"

const query = graphql`
  {
    images: allFile(
      filter: { relativeDirectory: { eq: "pages/services/services-supply" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ServicesSupply = () => {
  const data = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <article className="text-container">
        <p>
          Thanks to close cooperation with fireplaces manufactures and suppliers
          we're able to offer you a wide range of designs and options for your
          home. From bespoke, custom-made fireplaces and stoves to all necessary
          accessories.
        </p>
        <p>
          If you converting your fireplace to gas fire, we can custom make
          burner to fit with your existing fire grate or provide a bespoke fire
          grates for all different fireplace styles, from antique to
          contemporary ones.
        </p>
        <p>
          We also supply different effects for gas burners, including wide
          selection of coal and high definition logs.
        </p>
        <div className="back-to-button">
          <Link to={"/services"} className="btn btn--small">
            Back to services
          </Link>
        </div>
      </article>
      <aside className="image-container">
        <ServicesImages data={data} />
      </aside>
    </Wrapper>
  )
}

export default ServicesSupply

const Wrapper = styled.main`
  .image-container {
    margin-bottom: 2rem;
    height: 100%;
  }
  .text-container p {
    line-height: 2;
  }
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4rem;

    .text-container {
      grid-column: 1 / span 6;
    }

    .image-container {
      grid-column: 7 / -1;
    }
  }
`

import React from "react"
import SectionTitle from "../../components/SectionTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageWrapper from "../../components/layout/PageWrapper"
import ServicesSupply from "../../components/content/ServicesSupply"

const GasFireplaceInstallers = () => (
  <Layout>
    <SEO
      title="Bespoke gas burners"
      description="Gas, Solid Fuel And Bio Fuel Fireplaces Design and Installation"
    />
    <PageWrapper>
      <SectionTitle
        title="Bespoke gas and bio fuel burners"
        strapline="Supplying gas and bio fuel burners and equipment"
      />
      <ServicesSupply />
    </PageWrapper>
  </Layout>
)

export default GasFireplaceInstallers
